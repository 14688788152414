import React from "react";

const LandingSecLogo = () => {
  return (
    <svg
      width="53"
      height="38"
      viewBox="0 0 53 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.27096 14.7596C1.21572 13.1492 1.21572 10.1973 1.21572 10.1973L1.16049 0.911869L1.10526 0H0.0552324V12.1272L0 38H1.49249L1.32679 15.9944L1.27156 14.7602L1.27096 14.7596Z"
        fill="#316252"
      />
      <path
        d="M45.4296 16.7994H20.1725L15.0881 16.5849L15.1433 12.6669V9.12377H44.49L51.785 9.4456L51.8402 16.7989L45.4296 16.7994ZM51.7304 0.0534668L51.7856 8.10404H21.3336L19.2324 8.15768L17.5189 8.10404H15.1433L15.0881 0.0534668H13.9828V3.70328L14.0381 6.81611L13.9276 9.44619L13.8171 25.4943H15.1433L15.0329 21.8445C15.0881 21.0393 15.0881 18.7853 15.0881 18.7853V17.7655H40.4004L43.5505 17.6583L44.9313 17.7655H51.8396L51.8949 21.7885V23.1295L51.8396 25.4908H53.0001L52.9449 19.4272L53.0001 18.032V0.0534668H51.7304Z"
        fill="#316252"
      />
      <path
        d="M19.2328 12.9887L18.9014 12.7741C18.7357 12.6668 18.6805 12.6132 18.6805 12.5059C18.6805 12.4523 18.6805 12.345 18.8462 12.345C18.8687 12.3419 18.8916 12.3439 18.9132 12.3509C18.9348 12.3578 18.9544 12.3696 18.9704 12.3852C18.9865 12.4008 18.9986 12.4199 19.0058 12.4408C19.013 12.4618 19.0151 12.484 19.0119 12.5059V12.6668H19.62V12.5059C19.62 12.0231 19.1782 11.9153 18.8462 11.9153C18.4043 11.9153 18.0723 12.0762 18.0723 12.5566C18.0524 12.6698 18.0748 12.7862 18.1356 12.8847C18.1964 12.9832 18.2915 13.0573 18.4037 13.0936L18.7903 13.3618C18.956 13.4691 19.0113 13.5227 19.0113 13.63C19.0113 13.7373 18.9561 13.8446 18.8456 13.8446C18.8231 13.8477 18.8001 13.8457 18.7786 13.8387C18.757 13.8317 18.7374 13.8199 18.7213 13.8043C18.7052 13.7887 18.6931 13.7697 18.6859 13.7487C18.6787 13.7278 18.6767 13.7055 18.6799 13.6836V13.5227H18.0171V13.6836C18.0171 14.2206 18.4037 14.325 18.8462 14.325C19.3989 14.325 19.6753 14.1112 19.6753 13.6836C19.6802 13.5378 19.6409 13.3938 19.5624 13.2695C19.4838 13.1451 19.3695 13.0458 19.2334 12.984"
        fill="#316252"
      />
      <path
        d="M21.4989 13.6329C21.4989 13.7402 21.4989 13.7938 21.3332 13.7938C21.1675 13.7938 21.1675 13.6865 21.1675 13.6329V11.9153H20.5042V13.6865C20.5042 14.1699 20.8908 14.2772 21.3332 14.2772C21.7757 14.2772 22.1623 14.1699 22.1623 13.6865V11.9153H21.5019V13.6329H21.4989Z"
        fill="#316252"
      />
      <path
        d="M24.3722 12.9887L24.0408 12.7741C23.8751 12.6668 23.8199 12.6132 23.8199 12.5059C23.8199 12.4523 23.8199 12.345 23.9856 12.345C24.0081 12.3419 24.031 12.3439 24.0526 12.3509C24.0742 12.3578 24.0938 12.3696 24.1098 12.3852C24.1259 12.4008 24.138 12.4199 24.1452 12.4408C24.1524 12.4618 24.1545 12.484 24.1513 12.5059V12.6668H24.7594V12.5059C24.7594 12.0231 24.3176 11.9153 23.9856 11.9153C23.5437 11.9153 23.2117 12.0762 23.2117 12.5566C23.1918 12.6698 23.2142 12.7862 23.275 12.8847C23.3358 12.9832 23.4309 13.0573 23.5431 13.0936L23.9298 13.3618C24.0955 13.4691 24.1507 13.5227 24.1507 13.63C24.1507 13.7373 24.0955 13.8446 23.985 13.8446C23.9625 13.8477 23.9395 13.8457 23.918 13.8387C23.8964 13.8317 23.8768 13.8199 23.8607 13.8043C23.8446 13.7887 23.8325 13.7697 23.8253 13.7487C23.8182 13.7278 23.8161 13.7055 23.8193 13.6836V13.5227H23.1565V13.6836C23.1565 14.2206 23.5431 14.325 23.9856 14.325C24.5383 14.325 24.8147 14.1112 24.8147 13.6836C24.8083 13.5402 24.7644 13.4007 24.687 13.2782C24.6097 13.1558 24.5016 13.0546 24.3728 12.984"
        fill="#316252"
      />
      <path
        d="M25.4231 12.3444H25.9208V14.2229H26.5812V12.3444H27.0783V11.9153H25.4231V12.3444Z"
        fill="#316252"
      />
      <path
        d="M28.2971 13.3105L28.4628 12.398L28.6285 13.3105H28.2971ZM28.0761 11.9153L27.5232 14.2229H28.1836L28.2388 13.686H28.6254L28.6807 14.2229H29.3411L28.7941 11.9153H28.0761Z"
        fill="#316252"
      />
      <path
        d="M30.8911 11.9153H30.2307V14.2229H30.8911V11.9153Z"
        fill="#316252"
      />
      <path
        d="M32.9934 13.3105L32.5515 11.9153H31.9434V14.2229H32.4963V12.882L32.9382 14.2229H33.5463V11.9153H32.9934V13.3105Z"
        fill="#316252"
      />
      <path
        d="M35.1503 13.3105L35.316 12.398L35.4817 13.3105H35.1503ZM34.9294 11.9153L34.3765 14.2229H35.0369L35.0969 13.6865H35.4835L35.5388 14.2235H36.1991L35.6474 11.9153H34.9294Z"
        fill="#316252"
      />
      <path
        d="M38.0793 13.6328C38.0793 13.7938 38.0241 13.7938 37.9136 13.7938H37.7497V13.2562H37.9154C38.0811 13.2562 38.0811 13.3098 38.0811 13.4171L38.0793 13.6328ZM37.6897 12.3443H37.8554C37.8779 12.3412 37.9008 12.3432 37.9224 12.3502C37.944 12.3572 37.9636 12.369 37.9797 12.3846C37.9957 12.4002 38.0078 12.4192 38.015 12.4402C38.0222 12.4611 38.0243 12.4834 38.0211 12.5053V12.6662C38.0243 12.688 38.0222 12.7103 38.015 12.7313C38.0078 12.7522 37.9957 12.7712 37.9797 12.7868C37.9636 12.8025 37.944 12.8142 37.9224 12.8212C37.9008 12.8282 37.8779 12.8302 37.8554 12.8271H37.6897V12.3443ZM38.2979 13.044C38.5188 12.9903 38.6845 12.8831 38.6845 12.507C38.6845 12.0242 38.3531 11.9164 37.9641 11.9164H37.0293V14.224H37.9136C38.4113 14.224 38.6875 14.1704 38.6875 13.5827C38.7427 13.2609 38.6323 13.0994 38.3009 13.0457"
        fill="#316252"
      />
      <path
        d="M40.342 11.9153H39.6816V14.2229H40.342V11.9153Z"
        fill="#316252"
      />
      <path
        d="M41.9995 11.9153H41.3391V14.2229H42.6101V13.7938H41.9995V11.9153Z"
        fill="#316252"
      />
      <path
        d="M44.102 11.9153H43.4417V14.2229H44.102V11.9153Z"
        fill="#316252"
      />
      <path
        d="M44.8215 12.3444H45.3192V14.2229H45.9796V12.3444H46.4773V11.9153H44.8215V12.3444Z"
        fill="#316252"
      />
      <path
        d="M48.0824 12.935L47.8032 11.9153H47.1428L47.751 13.472V14.2235H48.4114V13.472L49.0195 11.9153H48.3591L48.0824 12.935Z"
        fill="#316252"
      />
      <path
        d="M19.3436 2.25382L19.675 2.57566L20.1168 2.09232L19.675 1.66321L19.2883 2.03868L18.9569 1.71685L18.4592 2.20019L18.9569 2.6293L19.3436 2.25382Z"
        fill="#316252"
      />
      <path
        d="M18.5723 4.02482C18.6773 3.88215 18.8362 3.7857 19.0142 3.75662V4.34723C18.6276 4.29359 18.5165 4.18632 18.5165 4.07904C18.5164 4.07195 18.5177 4.0649 18.5204 4.05832C18.5231 4.05174 18.5272 4.04577 18.5323 4.04075C18.5375 4.03574 18.5436 4.0318 18.5504 4.02916C18.5572 4.02652 18.5645 4.02524 18.5717 4.0254M21.059 4.2936C21.1695 4.07904 21.2247 4.0254 21.3352 4.0254C21.5009 4.0254 21.6114 4.18632 21.6114 4.45451V4.56179C21.3855 4.57836 21.1589 4.54155 20.951 4.45451L21.0614 4.2936H21.059ZM18.9037 5.20605L19.1247 4.99149C19.2343 5.11058 19.372 5.20208 19.526 5.25814C19.6801 5.31419 19.8458 5.33313 20.009 5.31333C20.1795 5.30334 20.3473 5.26713 20.5061 5.20605C20.9006 5.31943 21.3105 5.3737 21.7218 5.36697L22.1637 4.93785C22.1637 4.83057 22.2189 4.72329 22.2189 4.61602V4.24054C22.2189 3.64992 21.8875 3.27445 21.3898 3.27445C21.0584 3.27445 20.7817 3.489 20.5055 4.02598L20.395 4.1869C20.3398 4.29418 20.2846 4.40146 19.9532 4.40146C19.677 4.40146 19.6218 4.34782 19.6218 4.13326V2.79053L18.9013 2.8978V3.05872C18.682 3.12456 18.4797 3.23528 18.3081 3.38342C18.1365 3.53156 17.9996 3.71369 17.9066 3.91754C17.9066 4.02482 17.8513 4.23937 17.8513 4.34665C17.8768 4.59438 18.0007 4.82271 18.1968 4.98309C18.3929 5.14346 18.6456 5.22324 18.9013 5.20546"
        fill="#316252"
      />
      <path
        d="M23.435 5.31313V2.09302L22.7146 2.2003L22.7698 5.36677L23.435 5.31313Z"
        fill="#316252"
      />
      <path
        d="M40.1232 3.0059L40.4546 3.32773L40.8964 2.8444L40.3993 2.41528L40.0127 2.79076L39.7918 2.41528L39.2947 2.95226L39.7918 3.38138L40.1232 3.0059Z"
        fill="#316252"
      />
      <path
        d="M25.3667 5.15123C25.5288 5.26025 25.7224 5.31658 25.9196 5.31215H39.1833C39.9572 5.31215 40.2333 5.20487 40.399 4.93667L40.62 5.25851C40.7304 5.25851 40.8961 5.31215 41.0618 5.31215C41.5595 5.31215 41.8909 5.20487 42.0014 4.93667L42.2223 5.25851C42.3328 5.25851 42.388 5.31215 42.4985 5.31215C42.9404 5.31215 43.2189 5.20487 43.3276 4.93667L43.5485 5.25851C43.659 5.25851 43.7142 5.31215 43.8247 5.31215C44.0578 5.333 44.2903 5.26693 44.475 5.12735C44.6598 4.98778 44.7829 4.78515 44.8195 4.56062V4.18514C44.7984 3.84081 44.7429 3.49929 44.6538 3.16541L43.9333 3.37997C44.0276 3.66085 44.1014 3.94783 44.1543 4.23878C44.1543 4.34606 44.0438 4.3997 43.7676 4.3997H43.4362L43.5467 3.64816L42.8263 3.75544L42.771 4.18456C42.771 4.34547 42.6606 4.39911 42.4397 4.39911H42.1083L42.2187 3.8085L41.4983 3.91578L41.4431 4.18397C41.4431 4.34489 41.3326 4.39853 41.0564 4.39853C40.8722 4.40977 40.6872 4.39185 40.5089 4.34548L40.6194 3.48666L39.8989 3.59394L39.8437 4.13092C39.8437 4.29184 39.678 4.34548 39.2355 4.34548H25.9742C25.6981 4.34548 25.6428 4.2382 25.5324 3.91636L25.2562 3.00391L24.5958 3.37938L24.872 4.2382C24.872 4.29183 24.9272 4.34547 24.9272 4.39911C24.8167 4.39911 24.651 4.45275 24.4853 4.45275C24.2644 4.45275 24.0435 4.39911 23.825 4.39911V5.25793C24.0602 5.31993 24.3018 5.35592 24.5454 5.3652C24.8319 5.33782 25.1119 5.26535 25.3745 5.15065"
        fill="#316252"
      />
      <path
        d="M47.7513 4.23971V2.14661L47.0309 2.25388V4.13243C47.0309 4.40063 46.9204 4.45427 46.6443 4.45427H46.5891C46.5979 4.23909 46.5794 4.02368 46.5338 3.81293L46.3681 2.63228L45.6477 2.79319L45.9791 4.45718H45.2587L45.1482 5.36963H46.7511C46.8947 5.36481 47.0357 5.33108 47.1652 5.2706C47.2947 5.21011 47.4098 5.12418 47.5033 5.01824C47.5969 4.9123 47.6667 4.78867 47.7085 4.65516C47.7502 4.52165 47.763 4.38117 47.7459 4.24262"
        fill="#316252"
      />
      <path
        d="M48.9138 5.31313V2.09302L48.1934 2.2003L48.2486 5.36677L48.9138 5.31313Z"
        fill="#316252"
      />
      <path
        d="M44.2131 22.1132V22.3277C44.1578 22.4887 43.9369 22.4887 43.8817 22.4887C43.6607 22.4887 43.5503 22.3814 43.5503 22.2741V21.1471H43.2183V22.1132C43.2183 22.2741 43.2183 22.3814 43.2735 22.435C43.3248 22.5493 43.4102 22.6461 43.5185 22.713C43.6269 22.7799 43.7533 22.8138 43.8817 22.8105C44.018 22.8208 44.1544 22.7903 44.2724 22.7232C44.3905 22.6561 44.4845 22.5555 44.542 22.435C44.5921 22.3546 44.6117 22.2597 44.5973 22.1668V21.1471H44.2659L44.2107 22.1132H44.2131Z"
        fill="#316252"
      />
      <path
        d="M45.8173 22.2205L46.0383 21.4689L46.2592 22.2205H45.8173ZM45.8173 21.1471L45.2644 22.7574H45.5958L45.7063 22.4356H46.3144L46.4249 22.7574H46.7563L46.2034 21.1471H45.8173Z"
        fill="#316252"
      />
      <path
        d="M47.9725 22.0595H48.7463V21.7884H47.9725V21.4153H48.8568V21.1471H47.6963V22.7574H48.912V22.4881H47.9725V22.0595Z"
        fill="#316252"
      />
      <path
        d="M22.7703 22.7025C22.7703 22.6488 22.8255 22.6488 22.8808 22.6488H22.9912V22.4879H22.8255C22.7151 22.4879 22.5494 22.5952 22.5494 22.7025C22.5457 22.7316 22.5487 22.7612 22.5582 22.789C22.5678 22.8168 22.5836 22.8423 22.6046 22.8634C22.5494 22.8634 22.5494 22.8634 22.4941 22.917L22.5494 23.0779C22.7151 23.0243 22.8808 23.0243 23.0471 22.9707V22.7561C23.0171 22.7525 22.9866 22.7555 22.958 22.7647C22.9293 22.774 22.9031 22.7894 22.8814 22.8098C22.8261 22.7561 22.7703 22.7561 22.7703 22.7025Z"
        fill="#316252"
      />
      <path
        d="M22.6594 20.7178V22.2209H22.9356V20.6642L22.6594 20.7178Z"
        fill="#316252"
      />
      <path
        d="M20.3931 22.3275C20.3931 22.1666 20.6141 22.0593 21.0535 21.952L21.1088 21.6302C20.9431 21.6838 20.7221 21.6838 20.6117 21.7911C20.5733 21.7701 20.5424 21.7384 20.5227 21.7002C20.503 21.662 20.4955 21.6189 20.5012 21.5765C20.5055 21.5209 20.5301 21.4687 20.5706 21.4292C20.6112 21.3898 20.6649 21.3657 20.7221 21.3614C20.8173 21.3577 20.9119 21.3761 20.9983 21.415V21.1468C20.8939 21.1031 20.7802 21.0847 20.6669 21.0932C20.6003 21.0884 20.5334 21.0976 20.4708 21.1202C20.4082 21.1428 20.3514 21.1783 20.3042 21.2242C20.257 21.27 20.2206 21.3253 20.1974 21.3861C20.1742 21.4469 20.1648 21.5119 20.1698 21.5765C20.1678 21.647 20.1816 21.7171 20.2102 21.7819C20.2388 21.8468 20.2816 21.9048 20.3355 21.952C20.2506 22.0028 20.1806 22.0739 20.1322 22.1585C20.0839 22.2432 20.0588 22.3385 20.0593 22.4353C20.0593 22.7572 20.3355 22.9187 20.7197 22.9187C20.8511 22.9273 20.9828 22.909 21.1064 22.865L21.1616 22.5432C21.0391 22.5911 20.9064 22.6095 20.775 22.5968C20.4988 22.5968 20.3883 22.4896 20.3883 22.3286"
        fill="#316252"
      />
      <path
        d="M18.4608 21.1472C18.3974 21.1514 18.3354 21.1677 18.2785 21.1954C18.2216 21.223 18.1709 21.2613 18.1294 21.3081V21.5763C18.1649 21.5403 18.2079 21.5122 18.2556 21.4937C18.3032 21.4752 18.3543 21.4668 18.4056 21.469C18.4627 21.4733 18.5164 21.4973 18.5569 21.5366C18.5974 21.576 18.6221 21.6281 18.6265 21.6836V21.7372H18.2951C18.0189 21.7372 17.9085 21.8981 17.9085 22.22C17.9057 22.435 17.9242 22.6497 17.9637 22.8613L18.2951 22.754C18.241 22.5606 18.204 22.363 18.1846 22.1634C18.1846 22.0561 18.2399 22.0561 18.3503 22.0561C18.4632 22.0552 18.5753 22.0734 18.6817 22.1098L18.8474 21.9489C18.8474 21.8416 18.9027 21.7879 18.9027 21.7343C18.9027 21.3588 18.737 21.1437 18.4608 21.1437"
        fill="#316252"
      />
      <path
        d="M7.62586 12.666C8.89682 12.666 10.1131 12.1827 10.1131 10.3047V7.67462C10.1348 7.42015 10.1001 7.16413 10.0113 6.92379C9.92251 6.68345 9.78174 6.46437 9.59846 6.28129C9.41518 6.09822 9.19364 5.9554 8.94876 5.86245C8.70387 5.7695 8.44133 5.72858 8.17879 5.74244C7.63008 5.73626 7.09842 5.92754 6.6863 6.27942C6.6863 6.27942 6.6863 4.61543 6.74154 4.23879H10.0021V2.79053H5.30488C5.30488 3.21964 5.19441 8.05068 5.19441 8.05068H6.57524C6.57338 7.92331 6.59784 7.79688 6.64717 7.67885C6.69651 7.56082 6.76971 7.4536 6.86246 7.36353C6.95521 7.27346 7.06562 7.20236 7.18715 7.15445C7.30868 7.10654 7.43888 7.08278 7.57003 7.08459C8.17819 7.08459 8.39912 7.46007 8.39912 7.99704V10.5735C8.4144 10.6827 8.40348 10.7939 8.36722 10.8983C8.33096 11.0028 8.27033 11.0977 8.19007 11.1756C8.10981 11.2536 8.01209 11.3125 7.90454 11.3477C7.79698 11.3829 7.6825 11.3935 7.57003 11.3787C7.46548 11.3904 7.35954 11.3791 7.26015 11.3454C7.16075 11.3118 7.07046 11.2568 6.99604 11.1845C6.92162 11.1123 6.86498 11.0246 6.83037 10.9281C6.79576 10.8315 6.78407 10.7287 6.79617 10.6271V9.49953H5.08455V10.5193C4.97408 11.8072 5.74494 12.666 7.62706 12.666"
        fill="#316252"
      />
      <path
        d="M8.01321 20.45L8.89754 19.5912C9.8371 18.6787 10.1133 18.2502 10.1133 17.2299V15.9414C10.1133 14.331 8.95277 13.8483 7.51552 13.8483C6.02302 13.8483 5.1393 14.5479 5.1393 16.0486V17.2829H6.79749V16.1023C6.79749 15.6189 6.90795 15.1362 7.62658 15.1362C8.06845 15.1362 8.34701 15.4044 8.34701 15.995V17.122C8.34701 17.8736 8.23655 17.9272 6.74406 19.376C5.41786 20.664 4.92017 21.5764 4.92017 23.6695V23.7232H10.2255V22.2749H6.90795C6.90795 21.4697 7.51611 20.9339 8.01321 20.45Z"
        fill="#316252"
      />
    </svg>
  );
};

export default LandingSecLogo;
